import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "../../src/config/theme";
import {
  metaDescription,
  metaKeywords,
  appTitle,
} from "../../src/config/site.config";
import { fontCdn } from "../../src/config/theme/fonts";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${appTitle}`}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />

        <meta name="robots" content="max-image-preview:large" />
        <link rel="canonical" href="https://arianahakami.com/" />

        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="Ariana Hakami - For California CD-36"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Home - Ariana Hakami for California"
        />
        <meta
          property="og:description"
          content="Click To Play ABOUT ARIANA HAKAMI I’m Ariana Hakami and I want to be your Congresswoman. I encourage you to read on and learn more about me. I look forward to meeting you on the campaign trail. I want to hear your views and how I can best serve the residents of the 36th Congressional District. I will be a Congresswoman committed to improving our West Coast quality of life and not East Coast political intrigue."
        />
        <meta property="og:url" content="https://arianahakami.com/" />

        <meta
          property="article:publisher"
          content="https://facebook.com/arianahakami"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@arianahakami" />
        <meta
          name="twitter:title"
          content="Home - Ariana Hakami for California"
        />
        <meta
          name="twitter:description"
          content="Click To Play ABOUT ARIANA HAKAMI I’m Ariana Hakami and I want to be your Congresswoman. I encourage you to read on and learn more about me. I look forward to meeting you on the campaign trail. I want to hear your views and how I can best serve the residents of the 36th Congressional District. I will be a Congresswoman committed to improving our West Coast quality of life and not East Coast political intrigue."
        />
        <meta name="twitter:creator" content="@arianahakami" />

        <meta property="og:type" content="website" />

        <meta
          property="og:title"
          content="Ariana Hakami - For California CD-36"
        />
        <meta
          property="og:description"
          content="Ariana Hakami - For California CD-36. I'm running as a moderate, committed to the principle of putting our country and the interests of our district before any party affiliation"
        />
        <meta property="og:url" content="https://arianahakami.com/" />
        <meta property="og:site_name" content="Ariana Hakami for California" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/arianahakami"
        />

        <meta
          property="og:image"
          content="https://arianahakami.com/mobile-preview.png"
        />
        <meta property="og:image:width" content="1260" />
        <meta property="og:image:height" content="735" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ariana Hakami - For California CD-36"
        />
        <meta
          name="twitter:image"
          content="https://arianahakami.com/mobile-preview.png"
        />
        <meta name="twitter:site" content="@arianahakami" />

        <meta name="keywords" content={metaKeywords} />

        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href={fontCdn} rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
