import { createTheme } from "@mui/material/styles";
import { lighten, darken } from "polished";

import {
  primaryColor,
  secondaryColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  customColorStyles,
} from "./theme.config";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    accent: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
    accent: PaletteOptions["primary"];
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: lighten(0.1, primaryColor),
      main: primaryColor,
      dark: darken(0.1, primaryColor),
    },
    secondary: {
      light: lighten(0.1, secondaryColor),
      main: secondaryColor,
      dark: darken(0.1, secondaryColor),
    },
    common: {
      ...commonColorStyles,
    },
    ...customColorStyles,
  },
  typography: {
    fontFamily: fontFamilies,
    h1: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    h2: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    h3: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    h4: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    h5: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    h6: {
      fontFamily: headlineFont,
      fontWeight: 800,
    },
    subtitle1: {
      fontFamily: bodyFont,
    },
    subtitle2: {
      fontFamily: bodyFont,
    },
    body1: {
      fontFamily: bodyFont,
      color: commonColorStyles.grey,
    },
    body2: {
      fontFamily: bodyFont,
    },
    button: {
      fontFamily: bodyFont,
    },
    caption: {
      fontFamily: bodyFont,
    },
    overline: {
      fontFamily: bodyFont,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontFamily: bodyFont,
        },
      },
    },
  },
});

export default theme;
